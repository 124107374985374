@import '../../styles/customMediaQueries.css';

.root {
    width: 100%;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;

    @media (--viewportMedium) {
        flex-direction: row;
        gap: 20px;
    }
}

.input {
    border: 1px solid #e5e5e5;
    flex: 1;
    padding: 6px 16px;
    color: #4A4A4A;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.inputError {
    border: var(--borderErrorField);
}

.label {
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.04em;
    color: var(--colorBlack);
    text-wrap: nowrap;
    padding: 0;
    margin: 0;
    width: 100%;

    @media (--viewportMedium) {
        min-width: 300px;
        width: unset;
    }
}