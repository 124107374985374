@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 20px;
  }
}

.label {
  width: 100%;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #000000;
  text-wrap: nowrap;
  margin: 0;
  padding: 0;

  @media (--viewportMedium) {
    min-width: 300px;
    width: unset;
  }
}

.optionList {
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.option {
  padding: 13px 18px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.optionBtn {
  color: var(--colorBlack);
  font-weight: 450;
  text-align: left;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.04em;
  margin: 0;
  padding: 0;
  text-wrap: nowrap;
  width: 100%;
  outline: none;
  border: none;
  cursor: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  &:focus,
  &:hover {
    color: var(--colorBlack);
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.details {
  font-weight: 450;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.5);
  white-space: normal;
}

.icon {
  width: 24px;
}