.root {
    width: 100%;
    height: 100%;
    /* min-height: 200px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    aspect-ratio: 1 / 1;

    /* Colors */
    background-color: var(--colorWhite);

    @media (--viewportLarge) {
        background-color: var(--colorGrey50);
    }

    border-style: dashed;
    border-color: var(--colorGrey100);
    border-width: 2px;
    border-radius: 2px;

    /* Behaviour */
    cursor: pointer;
    transition: var(--transitionStyleButton);

    &:hover {
        border-color: var(--colorBlack);

        .button {
            color: var(--colorBlack);
        }
    }
}

.button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    padding: 10px 20px;

    &:hover {
        color: var(--colorBlack)
    }

}