@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: flex;
  width: 100%;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 20px;
  }
}

.label {
  width: 100%;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #000000;
  text-wrap: nowrap;
  margin: 0;
  padding: 0;

  @media (--viewportMedium) {
    min-width: 300px;
    width: unset;
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--colorWhite);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--colorWhite);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.iconSpinner {
  margin: auto;
  width: 23px;
}

.input {
  flex-grow: 1;
  height: 50px;
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border-bottom-width: 2px;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

/*
Predictions container can be overriden with new container styles for
size and position, etc.
*/
.predictionsRoot {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.predictionsRootGoogle {
  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
  padding-bottom: 72px;
}

.predictionsRootMapbox {
  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
  /* padding-bottom: 8px; */
}

/*
The Google Maps API TOS requires us to show a Powered by Google logo
next to the autocomplete service predictions. It is rendered to the
bottom of the container.
*/
.poweredByGoogle {
  position: absolute;
  bottom: 30px;
  width: 100%;
  height: 18px;
  background-image: url(./images/powered_by_google_on_non_white_hdpi.png);
  background-size: auto 18px;
  background-position: center;

  @media (--viewportMedium) {
    background-position: center left 36px;
  }
}

/* List of predictions, with a responsive padding size */
.predictions {
  composes: marketplaceBodyFontStyles from global;

  margin: 0;
  /* padding: 14px 0; */
  padding: 0;

  & li {
    /* color: black; */
    transition: var(--transitionStyleButton);
    margin: 0;
    /* Remove the double white space */
    /* line-height: 24px; */
    /* keep horizontal space stable when hovering */
    /* border-left: 6px solid transparent; */

    /* Assign enough vertical padding to make the element at least 44px high */
    /* padding: 9px 24px; */

    @media (--viewportMedium) {
      /* color: black; */
      /* padding: 10px 36px; */
      /* margin: 0; */
    }

    &:hover,
    &.highlighted {
      /* cursor: pointer; */
      /* border-left: 6px solid var(--colorWhite); */
      /* border-top-right-radius: 20px; */
      /* border-bottom-right-radius: 20px; */
      /* color: black; */
    }

    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    cursor: pointer;
    padding-left: 10px;
    font-style: normal;
    color: #4A4A4A;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding-top: 5px;
    padding-bottom: 5px;


    &:hover {
      background-color: var(--marketplaceColor);
      color: white;
    }
  }
}

.currentLocation {
  composes: p from global;
}

.currentLocationIcon {
  margin-right: 10px;
  margin-bottom: 1px;
}