.root:hover {
    text-decoration: none;
}

.button {
    background-color: transparent;
    /* font-family: 'Neue Haas Grotesk Display Pro'; */
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: var(--marketplaceColor);
    border: 1px solid var(--marketplaceColor);
    border-radius: 0;
    padding: 0 20px;
    min-height: 38px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    letter-spacing: 1.2px;

    &:hover {
        color: var(--colorWhite);
        background-color: var(--marketplaceColor);
    }
}

.button:hover svg path,
.button:focus svg path {
    fill: var(--colorWhite);
}