@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 20px;
  }
}

.input {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  flex: 1;
  padding: 6px 16px;
  line-height: 32px;
}

.input::placeholder {
  font-weight: 450;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.64px;
  color: rgba(0, 0, 0, 0.2);
}

.textarea {}

.inputSuccess {
  font-weight: 450;
}

.inputError {
  border: var(--borderErrorField);
}

.label {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: var(--colorBlack);
  text-wrap: nowrap;
  margin: 0;
  padding: 0 0 8px;

  @media (--viewportMedium) {
    min-width: 300px;
    width: unset;
  }
}