@import '../../styles/customMediaQueries.css';

.favoritesButtonWrapper {
    position: absolute;
    right: 20px;
    top: 15px;
    width: fit-content;
    height: auto;
    /* z-index: 1; */
}

.favoritesButtonNotSelected {
    cursor: pointer;
    display: block;
    transform: scale(1.3);

    &:hover {
        transform: scale(1.4);
        cursor: pointer;
    }
}

.favoritesButtonSelected {
    cursor: pointer;
    display: block;
    transform: scale(1.3);

    &:hover {
        transform: scale(1.4);
        cursor: pointer;
    }
}