@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  font-weight: var(--fontWeightMedium);
  color: var(--colorBlack);
  text-align: left;
  font-size: 18px;
  line-height: 33px;
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;

  &:focus,
  &:hover {
    color: var(--marketplaceColor);
  }
}

.labelSelected {
  color: var(--marketplaceColor);
}

.checkboxWrapper {
  display: block;
  align-items: center;
  cursor: pointer;
}

.text {
  padding-top: 3px;
}

.selected {
  color: var(--marketplaceColor);
}