@import '../../styles/customMediaQueries.css';

.root {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 36px;
    font-size: 24px;
    text-transform: uppercase;
    color: var(--colorBlack);

    @media (--viewportLarge) {
        width: unset;
    }
}

.ctaButton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: 0;
    color: var(--colorWhite);
    background-color: var(--colorBlack);
    font-weight: var(--fontWeightRegular);
    cursor: pointer;
    transition: ease-in-out 0.2s;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1.2px;

    &:hover {
        background-color: var(--marketplaceColor);
        color: var(--colorBlack);
    }

    &:disabled {
        box-shadow: none;
        cursor: not-allowed;
        text-decoration: none;
        background-color: var(--colorGrey100);
        color: var(--colorGrey700);
    }

    @media (--viewportLarge) {
        min-width: 200px;
        width: fit-content;
    }
}

.isFollowing {
    background-color: var(--marketplaceColorDark);
}