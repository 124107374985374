@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 15px;
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.info {
  color: var(--colorBlack);
  display: flex;
  gap: 12px;
  margin-bottom: 32px;
  font-size: 12px;
  line-height: 22px;
}

.infoTag {
  background-color: #D9D9D9;
  padding: 2px 12px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 0px;
  padding: 0;

  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.description {
  color: rgba(28, 28, 28, 1);
  font-size: 16px;
  line-height: 18px;
  padding: 0;
  margin: 20px 0 0 0;
  letter-spacing: 0.03em;
  font-weight: 500;
}

.price {
  font-weight: var(--fontWeightBold);
  color: var(--colorBlack);
  margin: 0;

  @media (--viewportMedium) {
    padding: 0;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 10px;
  }
}

.details {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0;
  margin: 0;

  li {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;
    padding: 0;

    label {
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.78px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.5);
      padding: 0;
      font-weight: 400;
    }

    strong {
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.78px;
      text-transform: uppercase;
      padding: 0;
      text-transform: uppercase;
      color: var(--colorBlack);
      text-align: end;
      font-weight: 400;
    }
  }
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {}
}

.author {
  composes: p from global;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 15px;
  border: 1px solid #E6E6E6;
  border-radius: 8px;

  @media (--viewportMedium) {
    margin: 20px 0 0 0;
    color: var(--colorBlack);
  }
}

.authorDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
}

.authorLinks {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contactAuthor {
  composes: textSmall from global;
  color: var(--colorBlack);
  text-transform: none;
  text-decoration: underline;
  cursor: pointer;
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    min-width: 60px;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.createdAt {
  composes: textSmall from global;
  color: var(--colorBlack);
  margin-bottom: 32px;
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin: 0;
    padding: 0;
    font-size: 40px;
    line-height: 48px;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;
  gap: 12px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  padding: 3px 0;

  @media (--viewportLarge) {
    flex-direction: row;
    padding: 20px 0;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
    border-bottom: 1px solid #E6E6E6;
  }
}

.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {}
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.productSubCategory {
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.65px;

}

.ctaButton {
  min-height: 45px;
  background-color: var(--colorBlack);
  border: 1px solid var(--colorBlack);
  color: var(--marketplaceColor);
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 1.35px;
  border-radius: 0;
  transition: ease-in-out 0.2s;

  &:hover,
  &:focus {
    background-color: var(--marketplaceColor);
    color: var(--colorBlack);
  }

  &:disabled:hover {
    background-color: var(--colorGrey100);
    color: var(--colorGrey700);
  }
}