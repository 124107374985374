@import '../../styles/customMediaQueries.css';

.root {
  margin-bottom: 16px;
}

.optionList {
  margin: 0;

  .optionList {
    margin: 3px 0;
  }

  @media (--viewportMedium) {
    .optionList {
      margin: 4px 0;
    }
  }
}

.option {
  padding: 0;
}

.optionBtn {
  font-weight: var(--fontWeightMedium);
  color: var(--colorBlack);
  text-align: left;
  font-size: 18px;
  line-height: 36px;
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  &:focus,
  &:hover {
    color: black;
  }
}

.optionBtnSelected {
  composes: optionBtn;
  color: black;

  &:focus,
  &:hover {
    /* color: var(--marketplaceColorDark); */
  }
}

.optionBtnSelectedLowest {

  &:focus,
  &:hover {
    color: black;
  }
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}