@import '../../../styles/customMediaQueries.css';

.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
  background-color: var(--colorBlack);

  @media (--viewportMedium) {
    top: 0;
  }
}

.main {
  display: grid;
}