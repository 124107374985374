@import '../../styles/customMediaQueries.css';


.wrapper {
    min-width: 150px;
    width: 100%;
    padding-bottom: 24px;

    @media (--viewportMedium) {
        padding-bottom: 30px;
    }
}

.selectInput {
    width: 100%;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    height: 46px;
    border-radius: 6px;
    border: 1px solid #D8DCE6;
    background: #FFF;
    align-items: center;
    padding-left: 16px;
}

.selectInput:focus {
    background-color: #fff;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    transition: all ease-in-out 100ms;
}

.selectInput:focus-visible {
    outline: none;
}

.arrow {
    height: 24px;
    padding-bottom: 5px;
}

.selectedOption {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    cursor: pointer;
    /* padding-left: 16px; */
    font-style: normal;
    color: #4A4A4A;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    height: 100%;
    letter-spacing: -0.305px;
}

.searchTerm {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-style: normal;
    color: #cfcaca;
    font-size: 16px;
    font-style: normal;
    padding: 0 0 0 10px;
    margin: 0;

    &:hover {
        cursor: none;
    }

}

.noResults {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-style: normal;
    color: #b66c6c;
    font-size: 16px;
    font-style: normal;
    padding: 10px 0 0 10px;
    margin: 0;

    &:hover {
        cursor: none;
    }
}

.placeholder {
    composes: selectedOption;
    font-weight: 450;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.64px;
    color: rgba(0, 0, 0, 0.2);
}

.arrow {
    margin-left: 5px;
    margin-right: 14px;
    padding: 0;
}

.options {
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    min-height: 100px;
    max-height: 200px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transform: translate(0, 101%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 99999;
    overflow-y: auto
}

.option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    cursor: pointer;
    padding-left: 10px;
    font-style: normal;
    color: #4A4A4A;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: var(--transitionStyleButton);
}

.option:hover {
    background-color: var(--marketplaceColor);
    color: white;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    @media (--viewportMedium) {
        flex-direction: row;
        gap: 20px;
    }
}

.input {
    border: 1px solid #e5e5e5;
    flex: 1;
}


.label {
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.04em;
    color: var(--colorBlack);
    text-wrap: nowrap;
    padding: 0;
    margin: 0;
    width: 100%;

    @media (--viewportMedium) {
        min-width: 300px;
        width: unset;
    }
}

.selectedTags {
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: 100%;
}

.tag {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    height: 100%;
    padding: 4px 0;

    >span {
        height: 100%;
        padding: 0 12px;
        display: flex;
        align-items: center;
    }
}

.removeBtn {
    background-color: white;
    width: 20px;
    height: auto;
}