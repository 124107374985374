  @import '../../styles/customMediaQueries.css';

  .root {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 15px;
      /* border: 1px solid rgba(0, 0, 0, 0.2); */
      border: 1px solid #E6E6E6;
      border-radius: 8px;

      @media (--viewportMedium) {
          margin: 25px 0 0 0;
          color: var(--colorBlack);
      }
  }

  .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 12px;
      gap: 12px;
      /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
      border-bottom: 1px solid #E6E6E6;

  }

  .link {
      margin-left: auto;

      img {
          border-radius: 50%;
      }

  }

  .userName {
      composes: p from global;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 14px;
  }

  .reviewRating {
      display: block;
      display: flex;
      flex-direction: row;
      gap: 2px;
  }

  .reviewRatingStar {
      height: 13px;
      width: 13px;


      &:last-of-type {
          margin-right: 0;
      }
  }

  .info {
      display: flex;
      flex-direction: column;
      /* gap: 5px; */
  }

  .item {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 13px;
      font-weight: 450;
      color: rgba(28, 28, 28, 1);
  }